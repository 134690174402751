import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { debounce } from 'lodash';
import api from '../../services/api';
import { Box, Button, Fab, IconButton, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CachedIcon from '@mui/icons-material/Cached';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { NOTE_EDITOR_API_KEY } from '../../constants/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ConfirmationDialog } from '../../components/dialogs/ConfirmationDialog';
import { Colors, SessionNotesTemplate } from '../../enums/enums';
import { UserNotes, UserNotesRequest } from './coaching.interface';
import { notesEditorConfig } from '../Sessions/MeetingRoomDialog.constant';
import { User } from './CoachingSessionFullDTO';

interface NoteEditorProps {
  cohortId: string;
  participants: User[];
  onClose: () => void;
  userId: string;
  closeBtnOnLeft?: boolean;
}

interface NoteTemplatesResponse {
  id: string;
  value: string;
}

const NotesEditor = ({ cohortId, participants, onClose, userId, closeBtnOnLeft }: NoteEditorProps) => {
  const debounceDelayMs = 2000;

  const [notesTemplate, setNotesTemplate] = useState<string>(null);
  const [value, setValue] = useState(null);
  const [notesSaving, setNotesSaving] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [notesFetched, setNotesFetched] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userNodesId, setUserNotesId] = useState<string>(null);

  const editorRef = useRef(null);

  useEffect(() => {
    const url =
      participants?.length === 1 ? SessionNotesTemplate.OneOnOneNotesTemplate : SessionNotesTemplate.GroupNotesTemplate;
    api.get<NoteTemplatesResponse>(`/config/${url}`).then(({ data }) => {
      setNotesTemplate(data.value);
      fetchNotes(data.value);
    });
  }, []);

  const save = () => {
    if (editorRef.current) {
      saveNotes(editorRef.current.getContent({ format: 'raw' }), userNodesId);
    }
  };

  const resetNotes = () => {
    setDialogOpen(false);
    setValue(notesTemplate);
  };

  const fetchNotes = (noteInitialValue: string): void => {
    const url = `/user-notes/${userId}/${cohortId}`;
    api.get<UserNotes>(url).then(({ data }) => {
      const sessionNotesSavedValue = data.notes;
      setUserNotesId(data.id);
      setValue(sessionNotesSavedValue || noteInitialValue);
      setNotesFetched(true);
    });
  };

  const saveNotes = (content: any, notesId: string): void => {
    const payload: UserNotesRequest = { id: notesId, userId, groupId: cohortId, value: content };
    setNotesSaving(true);

    const url = `/user-notes/`;
    api
      .patch<UserNotes>(url, payload)
      .then(({ data }) => {
        setUserNotesId(data.id);
      })
      .catch((e) => console.error('Error Saving Notes: ', e))
      .finally(() => setTimeout(() => setNotesSaving(false), 1000));
  };

  const delayedSave = useCallback(debounce(saveNotes, debounceDelayMs), []);

  return (
    <>
      <Box sx={{ height: '100%', width: closeBtnOnLeft ? 'unset' : '850px' }}>
        <Box display="flex" alignItems="center" justifyItems="space-between">
          {closeBtnOnLeft && (
            <Box>
              <Tooltip title="Hide Notes" arrow placement="top">
                <Fab variant="extended" size="small" onClick={onClose} color="success" sx={{ ml: 1 }}>
                  <KeyboardDoubleArrowRightIcon />
                </Fab>
              </Tooltip>
            </Box>
          )}
          <Box flexGrow={1}>
            <Typography
              variant="h6"
              sx={{
                display: 'inline-block',
                verticalAlign: 'middle',
                marginLeft: '10px',
                backgroundColor: Colors.BackgroundDrawer,
                color: Colors.TextElevated
              }}
            >
              Sessions Notes
            </Typography>
            <Tooltip title="Reset Notes" arrow placement="top">
              <span>
                <Button
                  variant="outlined"
                  color="secondary"
                  endIcon={<CachedIcon />}
                  size="small"
                  sx={{ ml: 2, mr: 2, py: '1px' }}
                  onClick={() => {
                    setDialogOpen(true);
                  }}
                  disabled={!notesFetched || notesSaving}
                >
                  Reset
                </Button>
              </span>
            </Tooltip>
            <Tooltip title="Save Changes" arrow placement="top">
              <span>
                <Button
                  variant="outlined"
                  color="success"
                  sx={{ py: '1px' }}
                  endIcon={<SaveAltIcon />}
                  size="small"
                  onClick={save}
                  disabled={!notesFetched || notesSaving}
                >
                  {notesSaving ? 'Saving..' : ' Save'}
                </Button>
              </span>
            </Tooltip>
          </Box>
          {!closeBtnOnLeft && (
            <Box>
              <Tooltip title="Close Notes" arrow placement="top">
                <IconButton onClick={onClose} color="primary">
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>

        {!notesFetched && (
          <Box
            sx={{
              width: '100%',
              height: '95%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <div>
              <CircularProgress
                color="secondary"
                style={{
                  verticalAlign: 'middle'
                }}
              />
              <Typography sx={{ display: 'inline-block', pl: '1rem', verticalAlign: 'sub' }}>
                Loading notes..
              </Typography>
            </div>
          </Box>
        )}

        {notesFetched && (
          <Editor
            apiKey={NOTE_EDITOR_API_KEY}
            value={value}
            onInit={(evt, editor) => {
              editorRef.current = editor;
            }}
            onEditorChange={(newValue, editor) => {
              if (!isInitialLoad) {
                delayedSave(editor.getContent({ format: 'raw' }), userNodesId);
                setValue(newValue);
              }
              setIsInitialLoad(false);
            }}
            init={notesEditorConfig}
          />
        )}
      </Box>
      {dialogOpen && (
        <ConfirmationDialog
          isOpen={dialogOpen}
          title={<span style={{ color: Colors.DeepRed }}>Reset Notes?</span>}
          message={
            <span>
              Are you sure you want to reset your notes? <br /> All notes since the beginning of the cohort will be
              permanently deleted.
            </span>
          }
          onConfirmBtn={resetNotes}
          onCancelBtn={() => setDialogOpen(false)}
        />
      )}
    </>
  );
};

export default NotesEditor;